import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageAbout = props => (
    <Layout {...props}>
        <Seo title='About' />
        <Divider />
        <Stack>
            <Main>
                <PageTitle
                    header="Welcome to P.S. Church Leadership"
                    subheader='Have you ever felt like you were not told everything you need to know to do your job
well? You’ve graduated from seminary, bible college, took that dream job as a Pastor,
Ministry Leader, or other church leadership position only to find out that the reality of
Church Leadership is much more complicated than your training might have suggested.'
                />
                <h3>Sometimes you just wish someone would add a PS – postscriptum or postscript, to your job description that tells you all the other things you need to know.</h3>
                <h3>The Idea for PS Church Leadership was born out of that very real need. We want to help you with a few of those need-to-know, but rarely taught things and help you lead with excellence as you minister and help others.</h3>
                <h3>Full disclosure: Here at Believers Church of Statesboro, PS also stands for Pastor Scott. That’s me! I will be sharing a lot of my insights on things that I have learned from others and through trial and error over the years. Hence… PS Church Leadership!</h3>
                <h3>Hopefully, you will find some of these resources and ideas helpful as you move forward in your work or ministry.</h3>
            </Main>
        </Stack>
    </Layout>
)

export default PageAbout